/**
 * Tech page component
 */

import React, { Component } from "react";

import Header from "components/Common/Header";
import CitySelector from "components/Common/CitySelector";
import Partner from "components/Common/Partner";

import Markdown from "react-markdown";

// import Fab from './assets/fab.png'
// import G5 from './assets/5G_orange.png'
// import IOT from './assets/IoT_Connectivity_orange.png'
// import SmartAir from './assets/Smart_Air_Quality_Monitor_orange.png'
// import IOT from './assets/Machine-to-Machine.png'
// import LiveObjects from './assets/Device-Managment-Express.png'
// import LTEM from './assets/Antenna.png'
// import CloudConnect from './assets/Cloud-connect.png'
// import wifiapi from './assets/wifiapi.png'
// import Security from './assets/xdlab.png'
import HeaderImage from "./assets/tech.jpg";

import axios from "axios";

import "./index.scss";

const content = {
  //   orange: `
  //   **Orange offers the Innovation Labs teams free access to the latest technologies, products and programs, that startups can use to develop their prototypes during the Hackathons and further on, in the 3-month program.**

  // The teams can choose as many tech enablers as they need in order to develop their products. In order to do this, please contact the organizer, who will liaise with Orange.

  // <div class="imgwrap">
  // <img src="${Fab}" class="contentimg"/>
  // </div>

  // **Orange Fab program**

  // The best teams in Innovation Labs will be considered for Orange Fab – Orange&#39;s corporate accelerator, that will further support them in growing their businesses and validating their products on the market.

  // Orange Fab is a 1-year startup accelerator that supports entrepreneurs in developing innovative products and distribute them locally and globally. The program is looking for start-ups in Romania with solid products, at least at MVP level, that improve people&#39;s lives and set a trend on how people will live in the future.

  // Startups at least at MVP level can obtain support in validating their products with Orange clients and access to Orange's distribution network to grow their business. More details on [__https://www.orangefab.ro/en/**__](https://www.orangefab.ro/en/).

  // <div class="imgwrap">
  // <img src="${G5}" class="contentimg"/>
  // </div>

  // **5G Connectivity**

  // 5G is the future communication system technology  able to support new service scenarios to address diverse market segments demand, having as drivers IoT, Virtual Reality (VR), industrial control, ubiquitous on-demand coverage, as well as the opportunity to meet customized customer’s needs.

  // 5G  is expected to provide optimized support for a variety of different services, traffic load and end user communities, capable of simultaneously supporting multiple combinations of metrics as reliability, latency, throughput, positioning and availability.

  // Technology revolution is achievable with the introduction of new 5G concepts that will serve vertical’s applications from different domains such as  Automotive, Manufacturing, Media, Energy, eHealth, Public Safety and Smart Cities.

  // In order to obtain access to the 5G technology deployed by Orange or to 5G resources developed in Horizon2020 research projects SLICENET, MATILDA, 5G-EVE and 5G-VICTORI please contact the Tech Lounge team who will liaise with Orange.
  // LoRaWAN connectivity - IoT network

  // Teams can request access to the LoRa network deployed by Orange in Innovation Labs premises.
  // LoRa Network is built using LoRa Gateway from Kerlink and is linked with Orange IoT platform – Live Objects, for storing and processing data.
  // For details about LoRaWA network and protocols, please access: [_https://lora-alliance.org/about-lorawan_](https://lora-alliance.org/about-lorawan)
  // In order to obtain access this technology and documentation, contact the Tech Lounge team, that will interface with the Orange team.

  // <div class="imgwrap">
  // <img src="${IOT}" class="contentimg"/>
  // </div>

  // **Live Objects – IoT platform**

  // The teams that choose to design a project related to IoT and M2M will be offered support on building and testing their solution through our IoT platform, Live Objects, which includes SDKs.

  // Live Objects, offered by Orange, is a development platform that collects, stores and aggregates data from connected objects, includes analysis tool and allows building powerful applications relying on a rich set of APIs. More information about the platform capabilities can be obtained from [_https://liveobjects.orange-business.com/#/liveobjects_](https://liveobjects.orange-business.com/#/liveobjects).

  // In order to get access this technology and documentation, contact the Tech Lounge team, that will interface with the Orange team.

  // **Flexible Engine: the latest cloud infrastructure management for your applications**

  // Teams can request access to Flexible Engine - an innovative solution based on Openstack technology, an analysis platform service with Machine Learning Service (MLS) that helps users to:

  // *	Identify patterns in data to construct machine learning models
  // *	Use these models to process new data and make predictions on service apps
  // MLS is perfectly suited to scenarios where massive volumes of data need to be mined and analyzed.
  // *	Fraud detection
  // Insurance companies analyze policy holders' historical behavior data and build a fraud behavior model to identify those who apply for or gain insurance compensation by fraudulent claims.
  // *	Product recommendation
  // You can make a prediction on customers' willingness to subscribe some services and provide personalized service recommendation for them based on their personal attributes and behavior features.
  // *	Customer grouping
  // You can scientifically group customers using data mining and formulate strategies according to the characteristics of different customer groups. In this way, you can provide appropriate products and develop target marketing activities for customers and manage the customers, thereby improving customer satisfaction and commercial benefits.
  // *	Anomaly detection
  // You can use an automatic network detection system to predict suspect traffic or faulty devices according to real-time traffic analysis during network device running.
  // *	Preventive maintenance
  // You can create a prediction model for devices and provide preventive maintenance suggestions and plans to shorten downtime and reduce fault occurrence probability, which improves efficiency and reduces costs.
  // *	Driving behavior analysis

  // You can collect drivers' bad driving habits, for example, harsh acceleration and braking, aggressive steering, speeding, and driving tired, and construct models to analyze drivers' driving habits. Then, according to the analysis results, you can provide driver ratings for enterprise fleets to restrict drivers' behavior, offer driving habit improvement suggestions for individual drivers to reduce accidents and oil consumption, and deliver information to insurance companies for UBI scenarios.

  // The access to Flexible Engine account and documentation will be requested by e-mail to Tech Lounge, who will interface with the Orange Team.

  // <div class="imgwrap">
  // <img src="${wifiapi}" class="contentimg"/>
  // </div>

  // **Wi-Fi Smart City Data Feeds through APIs**

  // If you want to develop a smart city application you can use APIs that offer you statistic data from the smart city projects implemented by Orange in Alba Iulia, Iași, Suceava and Timișoara. All APIs use the widely popular REST architecture and JSON format. In addition to the API, participants will receive access to the web interface of Orange Romania’s Wi-Fi Analytics Platform (Purple Intelligent Spaces – [_https://purple.ai/_](https://purple.ai/)), where teams can use advanced tools like Logic Flows for different clients, creation of HTML pages, integration with 3rd Party Apps, Web-Hooks and others.

  // You can obtain: smart connectors for access to fixed Wi-Fi APIs or/and a smart connector for access to mobile Wi-Fi API. The first ones offer network, analytics or social media related data, the second one offers info such as latitude, longitude, speed, time, that can be used for estimating the ETA of public transportation vehicles, for example.
  // In order to obtain access this technology and documentation, contact the Tech Lounge team, that will interface with the Orange team.

  // <div class="imgwrap">
  // <img src="${Security}" class="contentimg"/>
  // </div>

  // **Cyber Security events API**

  // Teams in the security track will be provided access to the cyber security events API enabler from Business Internet Security platform, offered by Orange. The platform generates tons of events from a myriad of network security devices, endpoints and middleware. We built an API that serves normalized, anonymized JSON.

  // In order to obtain access this technology and documentation, contact the Tech Lounge team, that will interface with the Orange team.

  // <div class="imgwrap">
  // <img src="${SmartAir}" class="contentimg"/>
  // </div>

  // **uRADMonitor Air Quality APIs**

  // In partnership with Orange, Air Quality monitors were deployed by uRADMonitor in Cluj-Napoca, Alba Iulia, Timișoara, Iași and Bucharest on buses. The uRADMonitor API offers real time access to the environmental data collected by the uRADMonitor devices, both fixed and mobile monitors.  The mobile monitors are installed on buses, so the API can be used to get the bus location real time too.

  // The Air Quality data contains readings for atmospheric Particulate Matter PM2.5, Temperature, Pressure, Humidity, Volatile organic compounds, Carbon Dioxide, Formaldehyde, Ionizing Radiation.
  // Data demo dashboards:

  // * [_www.uradmonitor.com/cluj_](https://www.uradmonitor.com/cluj)
  // * [_www.uradmonitor.com/alba_](https://www.uradmonitor.com/alba)
  // * [_www.uradmonitor.com/timisoara_](https://www.uradmonitor.com/timisoara)
  // * [_www.uradmonitor.com/iasi_](https://www.uradmonitor.com/iasi)
  // * [_www.uradmonitor.com/bucuresti_](https://www.uradmonitor.com/bucuresti)

  // In order to obtain access to the APis and the documentation please contact Tech Lounge, who will liaise with uRADMonitor.
  // `,
  wyliodrin: `
Wyliodrin is a web IDE used to program and deploy embedded devices such as Raspberry Pi.

Using Wyliodrin you can remotely access your embedded boards and write applications that are stored in the cloud and deployed on multiple boards. You can use any programming language from visual ones, to Python, JavaScript, C/C++ or Bash scripts using an universal pin control library that exposes very simple functions such as "digitalRead" or "analogWrite".

Wyliodrin also offers a graphical debugging and control system based on graphs.

All Innovation Labs participants will receive a Developer account for free on the duration of the program. To redeem your free account please sing in to wyliodrin and then send an email to support@wyliodrin.com in which you specify the email address you signed in with.
`,
  genezio: `
Genezio is a serverless development & deployment platform, helping you accelerate development based on boilerplates, using a ready-made DevOps infrastructure and a type-safe connection infrastructure between front-end and back-end out-of-the-box.

You can simplify the deployment process for web and mobile applications with backend developed in TypeScript/JavaScript or Go and frontend created with React, Vue, Angular, Svelte, Flutter, Kotlin, or React Native - pick your favorite language.

Genezio has helped numerous startups accelerate their development over the past year, winning numerous hackathons and awards @ Innovation Labs, The Bucharest Hackathon, xDay Hackathon by MultiversX, Developer Week CloudX 2023, Bitdefender CyberHack.

You can now register on https://genezio.com and test the platform. After creating a Genezio account, please fill out [***this form***](https://share-eu1.hsforms.com/18fFe8b8YTdaDLEHtNRUa9gflc1e) to upgrade to the platform's PRO plan. Of course, the Genezio team will be there to support you during the Innovation Labs Hackathon and program.
`,
};

class Tech extends Component {
  static propTypes = {};
  static defaultProps = {};

  state = {
    selected: Object.keys(content)[0],
    partners: [],
  };

  componentWillMount() {
    axios
      .get("/api/partners/")
      .then((x) => this.setState({ ...this.state, partners: x.data }));
  }

  onClick = (selected) => this.setState({ ...this.state, selected });

  get options() {
    return Object.keys(content);
  }

  get content() {
    const { selected } = this.state;
    return content[selected];
  }

  get partner() {
    const { partners, selected } = this.state;

    const partner = partners.filter((x) => x.name.toLowerCase() === selected);

    if (partner.length === 0) {
      return null;
    }

    return <Partner {...partner[0]} />;
  }

  render() {
    const { selected } = this.state;
    const { onClick, options, content, partner } = this;

    const selectorProps = {
      selected,
      options,
      onClick,
    };

    return (
      <div>
        <Header title="Tech" image={HeaderImage} />
        <section className="Tech">
          <CitySelector {...selectorProps} />
          {partner}
          <Markdown escapeHtml={false} source={content} />
        </section>
      </div>
    );
  }
}

export default Tech;
